<template>
  <v-app id="inspire">
    <v-main>
      <router-view />
    </v-main>
    <div v-if="menu_user">
      <v-footer padless>
        <v-bottom-navigation :elevation="0" grow>
          <v-btn value="home" @click="Home">
            <v-icon>mdi-home</v-icon>
            <!-- <span>Home</span> -->
          </v-btn>

          <v-btn value="recent" @click="Riwayat">
            <v-icon>mdi-history</v-icon>

            <!-- <span>Riwayat</span> -->
          </v-btn>

          <v-btn value="nearby" @click="Profile">
            <v-icon>mdi-account</v-icon>

            <!-- <span>Profile</span> -->
          </v-btn>
        </v-bottom-navigation>
      </v-footer>
    </div>

    <div v-if="menu_admin">
      <v-footer padless>
        <v-bottom-navigation :elevation="0" grow>
          <v-btn @click="Assign">
            <span>Assign Pengambilan</span>
          </v-btn>

          <v-btn @click="Keluar">
            <span>Keluar</span>
          </v-btn>
        </v-bottom-navigation>
      </v-footer>
    </div>

    <div v-if="menu_driver">
      <v-footer padless>
        <v-bottom-navigation :elevation="0" grow>
          <!-- <v-btn  @click="Manifest">
            <span>Manifest</span>
          </v-btn> -->
          <v-btn @click="Incoming">
            <span>Incoming</span>
          </v-btn>
          <v-btn @click="Delivery">
            <span>Delivery</span>
          </v-btn>

          <v-btn @click="Keluar">
            <span>Keluar</span>
          </v-btn>
        </v-bottom-navigation>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({
    loading: false,
    menu_user: false,
    menu_admin: false,
    menu_driver: false,

    name_users: "",
    ID: "",
    drawer: null,
    selectedItem: 0,
    role: localStorage.getItem("role_id"),
  }),
  created: function () {
    const name_users = localStorage.getItem("name_users");
    const ID = localStorage.getItem("ID");
    const role_id = localStorage.getItem("role_id");
    const tokenFCM = localStorage.getItem("tokenFCM");
    console.log("Token APP", tokenFCM);
    this.ID = ID;
    this.name_users = name_users;
    if (role_id == "1") {
      this.menu_user = false;
      this.menu_admin = false;
      this.menu_driver = false;
    } else if (role_id == "2") {
      this.menu_user = false;
      this.menu_admin = true;
      this.menu_driver = false;
    } else if (role_id == "Operasional") {
      this.menu_user = false;
      this.menu_admin = false;
      this.menu_driver = true;
    }
  },

  methods: {
    async HandleLogout() {
      try {
        //localStorage.setItem("authenticated",false)
        localStorage.setItem("role_id", "");
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },

    async Riwayat() {
      this.$router.push("/history");
    },
    async Profile() {
      this.$router.push("/profile");
    },
    async Home() {
      this.$router.push("/");
    },
    async Assign() {
      this.$router.push("/assign");
    },
    async Keluar() {
      this.$router.push("/logout");
    },
    async Manifest() {
      this.$router.push("/manifest");
    },
    async Incoming() {
      this.$router.push("/incoming");
    },
    async Delivery() {
      this.$router.push("/list");
    },
    async Inbox() {
      this.$router.push("/inbox");
    },

    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>
<style>
#inspire {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.v-main {
  flex: 1;
  overflow-y: auto;
}

.v-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Adjust the z-index as needed to ensure it's above other content */
}

.mynik {
  font-size: 13px !important;
}

.mytitle {
  font-size: 24px !important;
}
</style>

