import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView'
import HomeView from '../views/HomeView'
import LogoutView from '../views/LogoutView'
import RequestView from '../views/RequestView'
import ProfileView from '../views/ProfileView'
import HistoryView from '../views/HistoryView'
import OTPView from '../views/OTPView'
import ListRequest from '../views/ListRequest'
import ListPickup from '../views/ListPickup'
import InboxView from '../views/InboxView'
import Downline from '../views/ProfileDownlineView'
import Delivery from '../views/DeliveryPlanView'

import ListManifest from '../views/ListManifest'
import ListIncoming from '../views/ListIncoming'
import ListDelivery from '../views/ListDelivery'

import Lokasi from '../views/LokasiView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
  ,
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView

  }
  ,
  {
    path: '/request',
    name: 'request',
    component: RequestView

  },{
    path: '/profile',
    name: 'profile',
    component: ProfileView

  },{
    path: '/history',
    name: 'history',
    component: HistoryView

  },{
    path: '/otp',
    name: 'otp',
    component: OTPView

  },{
    path: '/assign',
    name: 'assign',
    component: ListRequest

  }
  ,{
    path: '/pickup',
    name: 'pickup',
    component: ListPickup

  }
  ,{
    path: '/inbox',
    name: 'inbox',
    component: InboxView

  }
  ,{
    path: '/downline',
    name: 'downline',
    component: Downline

  }
  ,{
    path: '/delivery',
    name: 'delivery',
    component: Delivery

  },{
    path: '/manifest',
    name: 'manifest',
    component: ListManifest

  },{
    path: '/list',
    name: 'list',
    component: ListDelivery

  },{
    path: '/incoming',
    name: 'incoming',
    component: ListIncoming

  },{
    path: '/lokasi',
    name: 'lokasi',
    component: Lokasi

  }
  



]

const router = new VueRouter({
  mode: 'hash',
  routes
})

//const isLogin = true
router.beforeEach((to, from, next) => {
  const isLogin = JSON.parse(localStorage.getItem('authenticated'))
  if (to.name !== "login" && !isLogin) next({ name: 'login' })
  
  else next()
  //console.log(to, from, next)

})



export default router




