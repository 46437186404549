<template>
  <v-sheet>
    <div v-if="formlaunch">
      <v-img :max-width="full" cover src="/assets/img/launch.png"></v-img>
    </div>
    <v-form ref="form">
      <v-card-text>
        <div v-if="isLoading" class="loader-overlay"></div>
        <div v-if="formlogin">
          <div>
            <div class="custom-header"></div>
          </div>
          <br />
          <br />
          <br />
          <v-img cover height="250" src="/assets/img/beos_2.png"></v-img>
          <br />
          <v-text-field
            outlined
            label="Username"
            :rules="required"
            v-model="no_hp"
            hint="Contoh, birin"
            persistent-hint
          />

          <v-divider class="mx-4 mb-1"></v-divider>

          <div class="d-flex flex-column">
            <v-btn color="warning" @click="pin"> Lanjutkan </v-btn>
          </div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <!-- <div>
            Belum Punya Akun?<v-btn @click="daftar" text>Daftar</v-btn> 
          </div> -->
        </div>

        <div v-if="formpin">
          <div>
            <div class="custom-header">
              <v-btn @click="login" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="header-title"></div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <v-img cover height="250" src="/assets/img/beos_2.png"></v-img>
          <br />
          <v-text-field
            outlined
            label="Password"
            :type="ShowPassword ? 'number' : 'password'"
            :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="HandleClick"
            v-model="password"
            maxlength="10"
          />
          <v-divider class="mx-4 mb-1"></v-divider>

          <div class="d-flex flex-column">
            <v-btn color="warning" @click="HandleSubmit"> Login </v-btn>
          </div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <!-- <div class="d-flex flex-column">
            <v-btn @click="LupaPIN" text>Lupa PIN</v-btn>
          </div> -->
        </div>

        <div v-if="formdaftar">
          <div>
            <div class="custom-header">
              <v-btn @click="login" icon>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="header-title">Daftar</div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <v-text-field
            outlined
            label="Nama Anda"
            :rules="required"
            v-model="nama"
          />

          <v-text-field
            outlined
            label="No. Handphone"
            :rules="required"
            v-model="no_hp_registrasi"
            hint="Contoh, 62812131415"
            persistent-hint
          />

          <v-text-field
            outlined
            :rules="required"
            label="PIN"
            :type="ShowPassword ? 'number' : 'password'"
            :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="HandleClick"
            v-model="passwordawal"
            maxlength="6"
          />

          <v-text-field
            outlined
            :rules="required"
            label="PIN"
            :type="ShowPassword ? 'number' : 'password'"
            :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="HandleClick"
            v-model="konfirmasipassword"
            maxlength="6"
          />

          <v-text-field
            outlined
            label="No. Referal (Optional)"
            :rules="required"
            v-model="no_referal"
          />
          <v-divider class="mx-4 mb-1"></v-divider>
          <div>
            Dengan mendaftar, saya menyetujui<v-btn @click="login" text
              >Syarat & Ketentuan
            </v-btn>
          </div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <div class="d-flex flex-column">
            <v-btn color="warning" @click="HandleDaftar"> Daftar </v-btn>
          </div>
          <v-divider class="mx-4 mb-1"></v-divider>
          <div>Sudah Punya Akun?<v-btn @click="login" text>Login</v-btn></div>
          <br />
          <br />
          <br />
        </div>
        <div class="text-center">
          <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-card-text>
    </v-form>
  </v-sheet>
</template>


<script>
import axios from "axios";

//import swal from "sweetalert";

export default {
  name: "LoginView",

  data() {
    return {
      ShowPassword: false,
      no_hp_registrasi: "",
      password: "",
      formdaftar: false,
      formlogin: true,
      formlaunch: false,
      formpin: false,
      full: "100%",
      formotp: false,
      nama: "",
      no_hp: "",
      passwordawal: "",
      konfirmasipassword: "",
      no_referal: "",
      otp: "",
      isLoading: false,
      role_id: "",
      norules: [(v) => !!v || "No. Handphone is required"],
      //v => /.+@.+/.test(v) || "ID Karyawan must be valid"
      //v => /.+@.+/.test(v) || "ID Karyawan must be valid"
      numberRule: [
        (v) => {
          if (!v.trim()) return true;
          if (/^\d+$/.test(v)) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999999) return true;
          return "Number has to be between 0 and 99999";
        },
      ],
      required: [(v) => !!v || "required"],

      snackbar: false,
      text: "My timeout is set to 2000.",
      timeout: 2000,

      countdownTime: 2, // Waktu dalam detik (misalnya, 5 menit)
      remainingTime: 0,
      countdownInterval: null,
      tokenFCM: localStorage.getItem("tokenFCM"),
    };
  },
  mounted() {
    // Inisialisasi waktu tersisa saat komponen dimuat
    this.startCountdown();
    this.remainingTime = this.countdownTime;
  },
  methods: {
    startCountdown() {
      // Mulai countdown dan atur interval untuk mengurangkan waktu
      this.countdownInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          // Countdown selesai, hentikan interval
          clearInterval(this.countdownInterval);
          console.log("formlaunch", this.formlaunch);
          console.log("formLogin", this.formlogin);
          this.formlaunch = false;
          this.formlogin = true;
          console.log("Token Login is:", this.tokenFCM);
          //alert('Countdown selesai!');
        }
      }, 1000); // Interval setiap 1 detik
    },
    stopCountdown() {
      // Hentikan countdown
      clearInterval(this.countdownInterval);
    },
    beforeDestroy() {
      // Pastikan untuk membersihkan interval sebelum komponen dihancurkan
      this.stopCountdown();
    },
    async HandleClick() {
      this.ShowPassword = !this.ShowPassword;
    },
    async HandleClickKonfirm() {
      this.ShowPassword = !this.ShowPassword;
    },
    async HandleSubmit() {
      if (this.no_hp == "" || this.password == "") {
        this.snackbar = true;
        this.text = "Please Input Required Field";
      } else {
        this.isLoading = true;
        try {
          let authenticated;
          let url = "https://api.bdpcargo.co.id/";
          let key =
            "$2y$07$s8J1IQvUEVmn0jAE0yZncuLZs18wbEYikZAcwMSxwL9ZoE5coYFJu";

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": key,
          };
          const response = await axios.post(
            url + "login",
            {
              no_hp: this.no_hp,
              password: this.password,
              tokenFCM: this.tokenFCM,
            },
            { headers }
          );
          this.response = response.data;
          //console.log(response.data);
          if (response.data.status == "fail") {
            this.snackbar = true;
            this.text = "No. HP atau Password salah";
            authenticated = false;
          } else {
            authenticated = true;
            localStorage.setItem("authenticated", authenticated);
            localStorage.setItem("ID", response.data.data.username);
            localStorage.setItem("role_id", response.data.data.hak);
            localStorage.setItem("name_users", response.data.data.username);
            localStorage.setItem("user_status", response.data.data.status);
            localStorage.setItem("provinsi_id", response.data.data.kdorigin);
            localStorage.setItem("lokasi", response.data.data.namaorigin);
            localStorage.setItem("url", url);
            localStorage.setItem("key", key);
            localStorage.setItem("tokenFCM", response.data.data.tokenFCM);
            localStorage.setItem("no_referal", response.data.data.username);
            console.log("Token Login is:", this.tokenFCM);

            //localStorage.setItem("ip_static",response.data.data.ip_static);

            this.role_id = response.data.data.hak;
            if (
              this.role_id == "Super Admin" ||
              this.role_id == "Super Admin"
            ) {
              this.$router.push("/incoming");
            } else if (this.role_id == "2") {
              this.$router.push("/incoming");
            } else if (this.role_id == "Operasional") {
              this.$router.push("/incoming");
            }
            location.reload();
          }
          this.isLoading = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async HandleDaftar() {
      if (this.nama == "" || this.no_hp_registrasi == "") {
        this.snackbar = true;
        this.text = "Please Input Required Field";
      } else {
        this.isLoading = true;
        try {
          let authenticated;
          let url = "https://woles.ptppi.co.id/api/";
          let key =
            "$2y$07$s8J1IQvUEVmn0jAE0yZncuLZs18wbEYikZAcwMSxwL9ZoE5coYFJu";

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": key,
          };
          const response = await axios.post(
            url + "transaksi/save_m_user",
            {
              nama: this.nama,
              nohp: this.no_hp_registrasi,
              password: this.passwordawal,
              no_referal: this.no_referal,
              tokenFCM: this.tokenFCM,
            },
            { headers }
          );
          this.response = response.data;
          console.log(response.data);
          if (response.data.status == "fail") {
            this.snackbar = true;
            this.text = "No. HP Sudah Terdaftar";
            authenticated = false;
          } else {
            this.otp = response.data.otp;
            //console.log("OTP",this.otp);
            //this.sendWhatsAppMessage(this.no_hp_registrasi,this.nama,this.otp);
            authenticated = true;
            localStorage.setItem("authenticated", authenticated);
            localStorage.setItem("no_hp", response.data.data.no_hp);
            localStorage.setItem("otp", this.otp);
            localStorage.setItem("url", url);
            localStorage.setItem("key", key);
            localStorage.setItem("no_referal", response.data.data.no_referal);
            // localStorage.setItem("ID", response.data.data.id_user);

            // localStorage.setItem("role_id", response.data.data.role_id);
            // localStorage.setItem("name_users", response.data.data.nama);
            // localStorage.setItem("user_status", response.data.data.user_status);
            // localStorage.setItem("provinsi_id",response.data.data.provinsi_id);

            this.$router.push("/otp");
            //location.reload();
          }
          this.isLoading = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async Reset() {
      (this.email = ""), (this.password = "");
    },
    async daftar() {
      this.formdaftar = true;
      this.formlogin = false;
      this.formotp = false;
    },
    pin() {
      this.formpin = true;
      this.formlogin = false;
    },
    async LupaPIN() {
      if (this.no_hp == "") {
        this.snackbar = true;
        this.text = "Masukan No. Handphone Terlebih Dahulu";
      } else {
        this.isLoading = true;
        try {
          let authenticated;
          let url = "https://woles.ptppi.co.id/api/";
          let key =
            "$2y$07$s8J1IQvUEVmn0jAE0yZncuLZs18wbEYikZAcwMSxwL9ZoE5coYFJu";

          const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": key,
          };
          const response = await axios.post(
            url + "transaksi/generate_otp",
            {
              nohp: this.no_hp,
            },
            { headers }
          );
          this.response = response.data;
          //console.log(response.data);
          if (response.data.status == "fail") {
            this.snackbar = true;
            this.text = "No. HP Tidak Terdaftar";
            authenticated = false;
            this.login();
          } else {
            this.otp = response.data.otp;
            this.nama = "-";
            //console.log("OTP",this.otp);
            //this.sendWhatsAppMessage(this.no_hp,this.nama,this.otp);
            authenticated = true;
            localStorage.setItem("authenticated", authenticated);
            localStorage.setItem("no_hp", this.no_hp);
            localStorage.setItem("otp", this.otp);
            localStorage.setItem("url", url);
            localStorage.setItem("key", key);
            localStorage.setItem("no_referal", response.data.no_referal);

            this.$router.push("/otp");
          }
          this.isLoading = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async login() {
      this.formdaftar = false;
      this.formlogin = true;
      this.formpin = false;
    },
    sendWhatsAppMessage(no_hp, nama, otp) {
      let data = {
        to_number: no_hp,
        to_name: nama,
        message_template_id: "34594375-5498-42ad-9b45-36f39f0b064a",
        channel_integration_id: "0c589af4-c01b-433b-bb06-b5128f718dc3",
        language: {
          code: "id",
        },
        parameters: {
          body: [
            {
              key: "1",
              value: "data",
              value_text: otp,
            },
          ],
          buttons: [
            {
              index: "0",
              type: "url",
              value: otp,
            },
          ],
        },
      };

      let config = {
        method: "post",
        url: "https://service-chat.qontak.com/api/open/v1/broadcasts/whatsapp/direct",
        //url:'/api/open/v1/broadcasts/whatsapp/direct',
        headers: {
          Authorization: "ZTgIUdEYr1VTU55HcFrtWmbkGYstUVc0optDgsN-C7s",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          crossorigin: true,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          // Handle response as needed
        })
        .catch((error) => {
          console.log(error);
          // Handle error as needed
        });
    },
  },
};
</script>

<style>
.login-view {
  background-image: url("https://www.ptppi.co.id/wp-content/uploads/2022/01/PPI-ID-Food.png");
  background-size: cover;
}
.row_login {
  justify-content: center;
}
</style>
<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px; /* Adjust the height as needed */
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
}
</style>

